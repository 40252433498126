$(document).on('click', '.my-works-grid img', function(e){
    let elem = $(this)
    let item = elem.parents('.image')
    let wrapper = elem.parents('.my-works-grid')
    let items = wrapper.find('img')
    let index = item.index()
    let options = []
    
    items.each(function(){
        let img = $(this)
        let src = img.attr('src')
        
        options.push({
            src: src,
            type: 'image'
        })
    })

    Fancybox.show(
        options,
        {
            startIndex: index,
            infinite: false,
        }
    )

    e.preventDefault()
})