let notificationIndex = 1

window.closeNotif = notification => {
    notification.stop().slideUp(300)

    setTimeout(() => notification.remove(), 300)
}

window.addNotif = options => {
    const notifWrapper = $('.push-notifications')

    options.title = options.title ? `<h6>${options.title}</h6>` : ''
    options.text = options.text ? `<p>${options.text}</p>` : ''
    options.icon = options.icon || ''
    options.iconUser = options.iconUser || ''
    options.color = options.color || ''
    options.timeout = options.timeout || null
    options.onClose = options.onClose || null
    options.btns = options.btns || []
    options.link = options.link || null

    let timer = 0
    let icon = ''
    let elemType = 'div'
    let attrs = [`data-id="${notificationIndex}"`]
    let classes = ['notif-item']

    if(options.color) {
        classes.push(options.color + '-theme')
    }

    if(options.link) {
        elemType = 'a'
        attrs.push(`href="${options.link}"`)
        classes.push('prevent-focus')
    }

    if(options.icon || options.iconUser) {
        const iconClasses = ['avatar']

        if(options.iconUser) {
            icon = options.iconUser.charAt(0)
            iconClasses.push('online')
        }else if(options.icon) {
            iconClasses.push('icon-' + options.icon)
        }

        icon = `<span class="${iconClasses.join(' ')}">${icon}</span>`
    }

    let btns = []
    let btnsActions = {}

    if(options.btns.length) {
        $.each(options.btns, (key, btn) => {
            let btnClasses = ['btn', 'medium']
            let content = ''
            let elemType = 'button'
            let attrs = [`data-index="${key}"`]

            if(btn.link) {
                elemType = 'a'
                attrs.push(`href="${btn.link}"`)
            }

            if(btn.style) {
                btnClasses.push(btn.style)
            }

            if(btn.color) {
                btnClasses.push(btn.color + '-theme')
            }

            if(btn.onClick) {
                btnsActions[key] = btn.onClick
            }

            if(btn.type == 'text') {
                contentClasses = ['text']

                if(btn.icon) {
                    contentClasses.push('icon-' + btn.icon)
                }

                content = `<span class="${contentClasses.join(' ')}">${btn.text}</span>`
            }else if(btn.type == 'icon') {
                contentClasses = ['icon']

                if(btn.icon) {
                    contentClasses.push('icon-' + btn.icon)
                }

                content = `<span class="${contentClasses.join(' ')}"></span>`
            }

            btns.push(`<${elemType} class="${btnClasses.join(' ')}" ${attrs.join(' ')}>${content}</${elemType}>`)
        })
    }

    if(btns.length) {
        btns = `
            <div class="inline">
                <div class="line">${btns.join('')}</div>
            </div>
        `
    }else{
        btns = ''
    }

    let notification = `
        <${elemType} class="${classes.join(' ')}" ${attrs.join(' ')}>
            <div class="notif-wrapper">
                <div class="notif-content article-mc">
                    ${options.title}
                    ${options.text}
                    ${btns}
                </div>
                <span class="close-notif close-icon icon-close"></span>
            </div>
        </${elemType}>
    `

    notifWrapper.append(notification)

    notification = $(`.notif-item[data-id="${notificationIndex}"]`)
    notification.slideDown(300)

    $.each(btnsActions, (key, action) => {
        notification.find(`.btn[data-index="${key}"]`).click(e => {
            e.preventDefault()
            action(notification, options)
        })
    })

    notification.find('.close-notif').click(e => {
        e.preventDefault()

        if(options.onClose) {
            options.onClose(notification, options)
        }else{
            clearTimeout(timer)
            timer = 0

            closeNotif(notification)
        }
    })

    notification.resetTimer = () => {
        if(options.timeout) {

            if(timer) {
                clearTimeout(timer)
                timer = 0
            }
            timer = setTimeout(() => closeNotif(notification), options.timeout)
        }
    }

    notification.resetTimer()

    notification.on('mouseenter', () => {
        clearTimeout(timer)
        timer = 0
    })

    notification.on('mouseleave', () => {
        notification.resetTimer()
    })

    notificationIndex++

    playRing()
}

// addNotif({
//     title: 'Заголовок',
//     text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti voluptate.',
//     icon: 'success',
//     color: 'green',
//     timeout: 2000,
//     onClose: (notif, options) => {
//         console.log('Close');
//     },
//     btns: [
//         {
//             type: 'text',
//             text: 'Понял',
//             onClick: (notif, options) => {
//                 console.log('Btn 1');
//             }
//         },
//         {
//             type: 'text',
//             text: 'Понял',
//             icon: 'trash',
//             style: 'ghost',
//             color: 'red',
//             onClick: (notif, options) => {
//                 console.log('Btn 2');
//             }
//         }
//     ]
// })