$(document).on('click', '.load-tax:not(.active)', function(){
    let elem = $(this)
    let parent = elem.parent()
    let slug = elem.data('slug')
    let append = $('.articles-grid .line')

    parent.siblings().find('.active').removeClass('active')
    elem.addClass('active')

    requestAjax('load_tax', slug, res => {
        append.html(res)
        append.find('.blog-card').addClass('done animate')
    })
})