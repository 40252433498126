$(document).on('click', '.order-send', function(e){
    e.preventDefault()

    let elem = $(this)
    let type = elem.data('type')
    let product = elem.data('id')
    let form = elem.parents('form')
    let fields = [
        {
            name: 'name',
            required: false
        },
        {
            name: 'phone',
            required: false
        },
        {
            name: 'email',
            required: true
        },
        {
            name: 'message',
            required: false
        },
        {
            name: 'time',
            required: false
        },
        {
            name: 'date',
            required: false
        }
    ]
    let data = {}
    let errors = 0

    for(let field of fields) {
        let {name, required} = field
        let input = form.find('.' + name)

        if(input.length) {
            let val = input.getValue()

            if(val) {
                data[input.attr('name')] = val
            }

            if(required) {
                errors += input.check()
            }
        }

    }

    if(!errors) {
        elem.disable(true)

        requestAjax('send_request', {
            title: 'Request',
            data: data
        }, () => {
            let popup = elem.parents('.popup')

            popup.stop().fadeOut(300)

            for(let field of fields) {
                let {name} = field
                let input = form.find('.' + name)
        
                if(input.length) {
                    input.clear()
                }
                
            }

            addNotif({
                title: 'Success',
                text: 'Request successfully sent',
                icon: 'success',
                color: 'green',
                timeout: 6000
            })

            elem.enable()
        })
    }
    
})