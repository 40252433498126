let interval = 0

function animate() {
    let start = $(window).scrollTop()
    let end = (start + window.innerHeight) - (window.innerHeight * 0.1)
    let elements = $('.fade_in:not(.done), .slide_up:not(.done), .slide_down:not(.done), .slide_up_fixed:not(.done), .slide_down_fixed:not(.done), .scale_in:not(.done), .slide_left:not(.done)')

    if(elements.length) {
        elements.each(function(){
            let elem = $(this)
            let offset = elem[0].getBoundingClientRect().top + document.documentElement.scrollTop
            let elem_end = elem[0].getBoundingClientRect().bottom + document.documentElement.scrollTop

            if((start <= offset && end >= offset) || (start <= elem_end && end >= elem_end)) {
                elem.addClass('done')

                setTimeout(() => {
                    elem.addClass('animate')

                    setTimeout(() => {
                        elem.removeClass('scale_in fade_in slide_up slide_down slide_up_fixed slide_down_fixed')
                    }, 700)

                }, interval)

                interval += 200
            }
        })
    }
}

$(document).ready(function () {
    setTimeout(() => {
        let header = $('header.site-header')

        header.addClass('done animate')

        animate()

        setInterval(() => {
            if(interval) {
                interval = Math.max(0, interval - 10)
            }
        }, 1)

        $(window).on('scroll', animate)

    }, 300)
});