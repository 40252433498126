let load_more = $('.load-more')
let max_pages = load_more.data('max-pages')

if(load_more.length){
    let available = true
    let post_type = load_more.data('post-type')
    let tax = load_more.data('tax')
    let tax_slug = load_more.data('tax-slug')
    let page = Math.max(1, parseInt(load_more.data('page')))

    load_more.click(function(){
        if(available){
            load_more.disable(true)
            page++
                
            let data = {post_type, tax, tax_slug, page}

            available = false

            requestAjax('load_more', data, structure => {
                if(structure){
                    load_more.parent().before(structure)
                    available = true
                    load_more.enable()

                    if(max_pages == page) {
                        load_more.parent().remove()
                    }
                } else {
                    load_more.parent().remove()
                }
            })
        }
    })
}