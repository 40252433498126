const sliders = {
	'.my-works-grid .line': {
		arrows: false,
		mobileFirst: true,
		infinite: false,
		responsive: [
			{
				breakpoint: 1025,
				settings: 'unslick'
			}
		]
	},
	'.articles-grid > .line': {
		arrows: false,
		mobileFirst: true,
		infinite: false,
		slide: '.blog-card',
		responsive: [
			{
				breakpoint: 1025,
				settings: 'unslick'
			}
		]
	},
	'.single-line-steps > .line': {
		arrows: false,
		mobileFirst: true,
		infinite: false,
		responsive: [
			{
				breakpoint: 1025,
				settings: 'unslick'
			}
		]
	},
}

$.each(sliders, (name, options) => {
	const slider = $(name)

	if (slider.length) {
		slider.slick(options)
	}
})