$(document).on('click', '.clear-input', function() {
    let elem = $(this)
    let input = elem.siblings('input')

    input.val('').change()
})

$.fn.check = function() {
    let errors = 0
    let type = this.attr('type')

    if(this.hasClass('inline-date')) {
        let val = this.datepicker('getDate')

        if(!val) {
            errors++
            addNotif({
                title: 'Ошибка',
                text: 'Выберите дату',
                icon: 'close',
                color: 'red',
                timeout: 2000
            })
        }
    }else if(this.hasClass('link-field')) {
        let val = this.val()

        if(val.indexOf('https://') == -1 && val.indexOf('http://') == -1) {
            val = 'https://' + val
        }

        if(val.chartAt(val.length - 1) != '/') {
            val += '/'
        }

        try {
            new URL(val)
        } catch (e) {
            errors++
        }
    }else if(this.hasClass('email')){
        let val = $.trim(this.val())
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
        let emailTest = regex.test(val)

        if(!emailTest && val == ''){
            this.addClass('error')
            errors++
        }else if(!emailTest){
            this.addClass('error')
            errors++
        }else{
            this.removeClass('error')
        }

    }else if(type == 'checkbox'){
        if(this.prop('checked')){
            this.removeClass('error')
        }else{
            this.addClass('error')
            errors++
        }
    }else{
        let val = $.trim(this.val())
        let isMasked = this[0].mask ? true : false
    
        if(val) {
            this.removeClass('error')
        }else{
            errors++
            this.addClass('error')
        }
    
        if(isMasked && !errors) {
            if(val.indexOf('_') == -1) {
                this.removeClass('error')
            }else{
                errors++
                this.addClass('error')
            }
        }
        
        this.on('input', () => {
            this.removeClass('error')
        })
    
    }

    return errors
}

$.fn.disable = function(appendLoading = false) {
    this.attr('disabled', true)
    
    if(appendLoading && this.hasClass('btn')) {
        this.addClass('loading').append('<span class="spinner"></span>')
    }
}

$.fn.enable = function() {
    this.removeClass('loading')
    this.find('.spinner').remove()
    this.attr('disabled', false)
}

$.fn.getValue = function() {
    if(this.hasClass('inline-date')) {
        return this.datepicker('getDate')
    }else if(this.hasClass('select-input')) {
        let val = this[0].selected

        if(val) {
            return val
        }
    }else if(this.hasClass('link-field')) {
        let val = this.val()

        if(val.indexOf('https://') == -1 && val.indexOf('http://') == -1) {
            val = 'https://' + val
        }

        if(val.charAt(val.length - 1) != '/') {
            val += '/'
        }

        let check = val.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)

        return check ? val : ''
    }else{
        let hasMask = this[0].mask

        if(hasMask) {
            if(this[0].mask.type == 'time' || this[0].mask.type == 'date') {
                let val = $.trim(this.val())

                if(!val) {
                    return ''
                }else if(val.indexOf('_') != -1) {
                    return ''
                }

                return val
            }else if(this[0].mask.type == 'slug') {
                let val = $.trim(this.val())
                return val
            }else{
                return $.trim(this[0].mask.unmaskedValue)
            }
        }else{
            return $.trim(this.val())
        }
    }
}

$.fn.clear = function() {
    if(this.hasClass('inline-date')) {
        return this.datepicker('getDate')
    }else{
        let hasMask = this[0].mask

        if(hasMask) {
            this[0].mask.unmaskedValue = ''
        }else{
            this.val('')
        }
    }

    return this
}

$(document).on('change input', 'input', function(){
    let elem = $(this)

    elem.removeClass('error')
})